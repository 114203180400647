<template>
    <div v-if="mode=='background'"
        @error="onError"
        @click="$emit('click',$event)"
        :style="{backgroundImage: `url('${url}')`}"
        class="cover-img"></div>
    <img v-else
        @error="onError"
        @click="$emit('click',$event)"
        :alt="alt"
        :data-src="url"
        :src="url"
        class="full-img lazyload">
</template>

<script>
    import V from 'voUtils/V.js'


    var modes = {
    	'FIT_EXACT': 1,
    	'FIT_TO_WIDTH': 2,
    	'FIT_TO_HEIGHT': 3
    };

    export default {
    	voVueComponent: 'img-fallback',
    	props: {
    		mode: String,
    		alt: String,
    		base: String,
    		imgFormat: String,
    		imgWidth: String,
    		imgHeight: String,
    		imgMode: String,
    		imgScaleUp: String,
    		prefix: String,
    		placeholder: String,
    		img: Number
    	},
    	data() {
    		return {
    			failed: false
    		}
    	},
    	computed: {
    		url: function() {

    			var url, format;

    			var id = this.img;

    			//console.log('ID::', id);


    			if (id) {
    				url = this.base || '//dyn.v-office.com/image/';

    				format = this.imgFormat || 'p.mtn';

    				if (this.imgWidth && this.imgHeight) {
    					format = 'c.' + this.imgWidth + '.' + this.imgHeight;
    				}

    				if (this.imgScaleUp || this.imgMode) {
    					format += '.' + (this.imgScaleUp === 'true' ? '1' : '0');
    				}

    				if (this.imgMode) {
    					format += '.' + (modes[this.imgMode] || '0');
    				}

    				if (this.prefix) {
    					id = this.prefix + id;
    				}

    				//c.450.300.0.1.0
    				url = url + format + '/' + id + '.jpg';

    				//console.log('Blog::', url);

    			} else {
    				url = '//via.placeholder.com/';

    				if (this.imgWidth && this.imgHeight) {
    					url += this.imgWidth + 'x' + this.imgHeight;
    				} else {
    					url += '450x300';
    				}

    				/*if (this.placeholder) {
    					url += '&text=' + encodeURI(this.placeholder);
    				}*/
    			}

    			return url;


    		}
    	},
    	methods: {
    		onError: function() {
    			this.failed = true;
    		}
    	}


    };
</script>